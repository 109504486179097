import axios from 'axios';
import Cookies from 'js-cookie';
// const { REACT_APP_API_URL } = process.env;
import { apiBaseURL } from 'common-helpers/env-common';
import { systemEnv } from './env-common';
//const apiBaseURL = '/apis';


const fetchWrapper = axios.create({
  //baseURL: apiBaseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

fetchWrapper.interceptors.request.use(async config => {
  let tokensData = await JSON.parse(Cookies.get(`${systemEnv}tokens`));
  config.headers['Authorization'] = 'Bearer ' + tokensData.accessToken;
  return config;
},
  error => {
    Promise.reject(error)
  });

fetchWrapper.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalConfig = error.config;

    // if (!response.ok) {
    //   if ([401, 403].includes(response.status) && auth?.token) {
    //       // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    //       localStorage.removeItem('user');
    //       setAuth(null);
    //       // history.push('/login');
    //   }

    //if ([401, 403].includes(response.status) && !originalConfig._retry) {
    if (error.response)
      if (error.response.status === 401 && !originalConfig._retry) {

        let isRefreshingToken = false;

        if (Cookies.get(`${systemEnv}refreshingData`)) {
          let _refreshingData = JSON.parse(Cookies.get(`${systemEnv}refreshingData`));
          isRefreshingToken = _refreshingData.isRefreshingToken;
          //return jwt_decode(tokens.accessToken);
        }

        // console.log('isRefreshingToken==>', isRefreshingToken);


        if (isRefreshingToken) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalConfig.headers['Authorization'] = 'Bearer ' + token;
              return axios(originalConfig);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        Cookies.set(`${systemEnv}refreshingData`, JSON.stringify({ isRefreshingToken: true }));
        originalConfig._retry = true;
        const authData = JSON.parse(Cookies.get(`${systemEnv}tokens`));
        const payload = {
          accessToken: authData.accessToken,
          refreshToken: authData.refreshToken
        };

        let apiResponse = await axios.post(
          `${apiBaseURL}/auth0/api/authenticate/refresh-token`,
          payload
        );

        Cookies.set(`${systemEnv}tokens`, JSON.stringify(apiResponse.data));
        originalConfig.headers.Authorization = `Bearer ${apiResponse.data.accessToken}`;
        Cookies.set(`${systemEnv}refreshingData`, JSON.stringify({ isRefreshingToken: false }));
        processQueue(null, apiResponse.data.accessToken);


        return axios(originalConfig);
      } else {
        return Promise.reject(error);
      }
  }
);

export default fetchWrapper;
